<template>
  <span><textarea
    ref="cmTextArea"
    v-model="value"
    v-bind="$attrs"
    v-on="$listeners"/></span>
</template>

<script>
  import 'codemirror/lib/codemirror.css'
  import './gateway-ng.css'

  const GlobalCMOptions = {
    tabSize: 2,
    theme: 'gateway-ng',
    extraKeys: {
      // replace tabs with spaces
      Tab: cm =>
        cm.replaceSelection (Array (cm.getOption ('indentUnit') + 1).join (' '))
    }
  }

  export default {
    name: 'CodeEditor',

    props: {
      value: {
        // the code snippet to highlight
        type: String,
        required: true
      },
      lineNumbers: {
        // whether line numbers shall be displayed
        type: Boolean,
        default: false
      },
      lineWrapping: {
        // whether lines shall be wrapped
        type: Boolean,
        default: true
      },
      lines: {
        // the number of lines to display (display all if 0 or not specified)
        type: Number,
        default: undefined
      },
      autoGrow: {
        // if true, editor grows automatically if content exceeds initial size
        type: Boolean,
        default: false
      },
      readonly: {
        // whether the code shall not be editable
        type: Boolean,
        default: false
      },
      placeholder: {
        // the placeholder text
        type: String,
        default: undefined
      },
      options: {
        // the CodeMirror options; will override defaults
        type: Object,
        default: undefined
      },
      autoIndent: {
        // whether shall will be indented automatically
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        cm: null,
        model: ''
      }
    },

    watch: {
      value (newValue) {
        if (!newValue || newValue !== this.model) {
          // new value has been set from outside
          this.model = newValue
          this.cm.getDoc ().setValue (newValue)
        }
      },

      autoIndent (newValue) {
        this.setAutoIndent (newValue)
      },

      placeholder () {
        this.resetCMInstance ()
      },

      lines () {
        this.resetCMInstance ()
      }
    },

    mounted () {
      this.initCMInstance ()
    },

    methods: {
      /**
       * Initialize the CodeMirror instance with the global and specified
       * options and register the event handlers.
       */
      async initCMInstance () {
        const {default: CodeMirror} = await import ('codemirror')
        await import ('codemirror/addon/selection/active-line')
        await import ('codemirror/addon/display/placeholder')

        // create CodeMirror instance from textarea and set options
        this.cm = CodeMirror.fromTextArea (
          this.$refs.cmTextArea,
          Object.assign (
            {},
            GlobalCMOptions,
            {
              lineNumbers: this.lineNumbers,
              lineWrapping: this.lineWrapping,
              readOnly: this.readonly,
              styleActiveLine: !this.readonly,
              placeholder: this.placeholder
            },
            this.options
          ))

        const height = this.lines && !this.autoGrow
          ? this.lines * this.cm.defaultTextHeight ()
          : '100%'
        this.cm.setSize (null, height)

        // change event handler: emit input event; resize if autoGrow is enabled
        this.cm.on ('change', cmInstance => {
          const newValue = cmInstance.getDoc ().getValue ()

          // skip if new content has been set from outside (via the value prop),
          // (the model has then already been set by the corresponding watcher)
          if (newValue !== this.model) {
            this.model = newValue
            this.$emit ('input', newValue)
          }

          if (this.autoGrow) {
            this.cm.setSize (
              null,
              this.lines && cmInstance.doc.size < this.lines
                ? this.lines * this.cm.defaultTextHeight ()
                : '100%')
          }

          // should not be necessary, but without explicit refresh rendering is
          // sometimes incomplete
          this.cm.refresh ()
        })

        this.setAutoIndent (this.autoIndent)
      },

      /**
       * Reset the CodeMirror instance to the initial state but retain the
       * entered text.
       */
      resetCMInstance () {
        this.cm.toTextArea ()
        this.initCMInstance ()
      },

      /**
       * Set the automatic indentation of the code snippet.
       *
       * @param {Boolean} enabled     true to enable the automatic indentation,
       *                              false to disable it
       */
      setAutoIndent (enabled) {
        const event = 'change'
        const handler = this.indentLines

        if (enabled) {
          this.cm.on (event, handler)
          handler (this.cm)
        } else {
          this.cm.off (event, handler)
        }
      },

      /**
       * Perform automatic indentation on the document of the given CodeMirror
       * instance.
       *
       * @param {Object} cm     the CodeMirror instance to operate on
       */
      indentLines (cm) {
        for (let i = 0; i < cm.lineCount (); i++) {
          cm.indentLine (i)
        }
      }
    }
  }
</script>
