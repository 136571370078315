<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <base-layout mw1>
    <v-col cols="12">
      <template-create-update
        :is-create="true"
        @success="back"
        @cancel="back"/>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import TemplateCreateUpdate from './TemplateCreateUpdate'
  import BaseLayout from '@/app/core/components/BaseLayout'

  export default {
    name: 'TemplateCreate',

    components: {
      BaseLayout,
      TemplateCreateUpdate
    },

    methods: {
      back () {
        this.$router.back ()
      }
    }
  }
</script>
