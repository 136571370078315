<template>
  <v-radio-group
    v-model="myValue"
    :label="label"
    :column="column"
    hide-details>
    <v-row>
      <v-col
        v-for="(it, idx) in optionValues"
        :key="it"
        cols="auto"
        :class="radioClasses">
        <v-radio
          :disabled="disabled.includes (it)"
          :label="optionLabels[idx]"
          :value="it"/>
      </v-col>
    </v-row>
  </v-radio-group>
</template>

<script>
  export default {
    name: 'RadioGroup',

    props: {
      value: {
        // any type
        type: null,
        required: true
      },
      defaultValue: {
        // any type
        type: null,
        default: undefined
      },
      label: {
        type: String,
        default: undefined
      },
      disabled: {
        type: Array,
        default: () => []
      },
      optionValues: {
        type: Array,
        required: true
      },
      optionLabels: {
        type: Array,
        required: true
      },
      radioClasses: {
        type: Array,
        default: () => ([])
      },
      column: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      myValue: {
        get () {
          return this.value
        },

        set (newValue) {
          this.$emit ('input', newValue)
        }
      }
    },

    watch: {
      value (newValue) {
        this.normalizeValue (newValue)
      }
    },

    created () {
      this.normalizeValue (this.value)
    },

    methods: {
      normalizeValue (value) {
        this.myValue =
          this.optionValues.includes (value) ? value : this.defaultValue
      }
    }
  }
</script>
