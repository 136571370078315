import {idnDomain, fqDomain} from './domain'
import {toASCII as punycodeToASCII} from 'punycode/punycode.es6'

const validateDomain = (domain) => {
  const idn = punycodeToASCII (domain)
  return idnDomain (idn) || fqDomain (idn)
}

/**
 * Validate an email address. Only the domain is validated.
 * The local part will be ignored.
 * First the given string is split by '@' symbols.
 * Then it is checked it there are a least 2 parts in the split.
 * Then the last item of the split is validated to be a domain
 *
 * @param value
 * @returns {boolean|*}
 */

export const email = (value) => {
  if (!value) return false
  const parts = value.split ('@')

  if (parts.length <= 1 || parts[0].length === 0) {
    return false
  }

  return validateDomain (...parts.slice (-1))
}
