import {helpers} from 'vuelidate/lib/validators'

// IPv4 addresses and address ranges
const ipV4Address = /(?:(?:(?:[01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\.){3}([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]))/
const ipV4Subnet = /\/([1-9]|[1-2][0-9]|3[0-2])/
const ipV4 = new RegExp (ipV4Address.source + '(' + ipV4Subnet.source + ')?')

// IPv6 addresses and address ranges
const ipV6Address = /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/
const ipV6Subnet = /\/([1-9]|([1-9]|1[0-1])[0-9]|12[0-8])/
const ipV6 = new RegExp (ipV6Address.source + '(' + ipV6Subnet.source + ')?')

// IP addresses and address ranges validator
const ipExpr = new RegExp ('^((' + ipV4.source + ')|(' + ipV6.source + '))$')
export const ip = helpers.regex ('ip', ipExpr)
