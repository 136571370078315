<template>
  <code-editor
    v-model="model"
    :options="options"
    :readonly="readonly"
    :lines="lines"
    :auto-grow="autoGrow"
    :placeholder="placeholder"
    @input="$emit ('input', $event)"/>
</template>

<script>
  import CodeEditorMixin from './CodeEditorMixin'

  (async function defineMode () {
    const {default: CodeMirror} = await import ('codemirror')
    await import ('codemirror/addon/mode/simple')
    CodeMirror.defineSimpleMode ('macrotemplate', {
      start: [
        {
          // two consecutive open braces are interpreted as single open brace
          regex: /.*{{.*?/,
          token: null
        },
        {
          // {$...} at beginning of line is interpreted as value
          regex: /^{\$.*?}/,
          token: 'variable'
        },
        {
          // {$...} preceeded by arbitrary character except '{' is interpreted
          // as value
          regex: /([^{])({\$.*?})/,
          token: [null, 'variable']
        },
        {
          // {...} at beginning of line is interpreted as expression
          regex: /^{.*?}/,
          token: 'tag'
        },
        {
          // {...} preceeded by arbitrary character except '{' is interpreted
          // as expression
          regex: /([^{])({.*?})/,
          token: [null, 'tag']
        }
      ]
    })
  }) ()

  export default {
    mixins: [CodeEditorMixin],

    data () {
      return {
        options: {
          mode: 'macrotemplate'
        }
      }
    }
  }
</script>
