<template>
  <code-editor
    v-model="model"
    :options="options"
    :auto-indent="autoIndent"
    :readonly="readonly"
    :lines="lines"
    :auto-grow="autoGrow"
    :placeholder="placeholder"/>
</template>

<script>
  import CodeEditorMixin from './CodeEditorMixin'

  (async function init () {
    await import ('codemirror/mode/xml/xml')

    await import ('codemirror/addon/edit/closetag')
    await import ('codemirror/addon/edit/matchtags')
  }) ()

  export default {
    name: 'Xml',

    mixins: [CodeEditorMixin],

    props: {
      closeTags: {
        // whether entered XML-Tags shall be closed automatically
        type: Boolean,
        default: false
      },
      matchTags: {
        // whether matching start/end tags shall be highlighted
        type: Boolean,
        default: true
      },
      autoIndent: {
        // whether lines shall be indented automatically
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        options: {
          mode: 'xml',
          autoCloseTags: this.closeTags,
          matchTags: this.matchTags
            ? {bothTags: true}
            : false
        }
      }
    }
  }
</script>
