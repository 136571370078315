import CodeEditor from './CodeEditor'

export default {
  props: {
    value: {
      // the code snippet to highlight
      type: String,
      required: true
    },
    lines: {
      // the number of lines to display (display all if 0 or not specified)
      type: Number
    },
    autoGrow: {
      // if true, editor grows automatically if content exceeds initial size
      type: Boolean,
      default: false
    },
    readonly: {
      // whether the code will not be editable
      type: Boolean,
      default: false
    },
    placeholder: {
      // the placeholder text
      type: String
    }
  },

  components: {
    CodeEditor
  },

  computed: {
    model: {
      // internal model corresponds to value prop
      get () {
        return this.value
      },

      // changing the internal model emits input event
      set (newValue) {
        if (this.model !== newValue) {
          this.$emit ('input', newValue)
        }
      }
    }
  }
}
