<messages>["./ClientSelect"]</messages>

<template>
  <v-autocomplete
    ref="input"
    v-model="clientId"
    spellcheck="false"
    :items="selectableClients"
    :clearable="nullable && !selectionDisabled"
    :class="{ required }"
    :disabled="disabled"
    :label="labelString"
    :multiple="multiple"
    :readonly="selectionDisabled"
    :error-messages="validationErrors('', validationMessages)">
    <template #selection="{ item, parent }">
      <v-chip
        v-if="multiple && item.value !== null"
        :key="item.value"
        :close="nullable"
        outlined
        small
        color="black"
        class="my-1"
        @update:active="parent.selectItem(item)">
        <span
          class="chipContent"
          :title="item.text"
          v-text="item.text"/>
      </v-chip>
      <span
        v-else
        v-text="item.text"/>
    </template>
    <template #item="{ item }">
      <span :class="{'text--disabled': item.inactive}">{{ item.text }}</span>
    </template>
  </v-autocomplete>
</template>

<script>
  import {required} from 'vuelidate/lib/validators'
  import {mapActions} from 'vuex'

  import {formatClient} from '@/app/core/mixins/ClientNameFormatter'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  /**
   * validation object for book entry input
   */
  export const validation = {
    required
  }

  /**
   * validation type message attributes for the validation object
   */
  export const validationMessages = {
    required: 'validation.client.required'
  }

  const labelKey = 'label.client'

  export default {
    name: 'ClientSelect',

    mixins: [validationMixins],

    props: {
      value: {
        type: [Number, Array],
        default: undefined
      },
      label: {
        type: String,
        default: undefined
      },
      required: {
        type: Boolean,
        default: false
      },
      additionalItems: {
        type: Array,
        default: () => []
      },
      disabled: {
        type: Boolean,
        default: false
      },
      nullable: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      },
      forView: {
        type: Boolean,
        default: false
      },
      showInactive: {
        type: Boolean,
        default: false
      },
      forAccounting: {
        type: Boolean,
        default: false
      },
      /**
       * the vuelidate validation object (normally the part of parent's
       * {@code this.$v})
       */
      v: {
        type: Object,
        default: undefined
      },
      /**
       *  the validation messages applicable to specified validation object,
       *  which can override validation messages, specified in the component
       *  ({@code validationMessages})
       */
      vMessages: {
        type: Object,
        default: () => validationMessages
      }
    },

    data () {
      return {
        clients: [],
        inactiveClients: [],
        selectionDisabled: false
      }
    },

    computed: {
      clientId: {
        get () {
          return this.value !== undefined ? this.value : this.v?.$model
        },

        set (newValue) {
          if (
            typeof this.value === 'undefined' &&
            this.v &&
            typeof this.v.$model !== 'undefined'
          ) {
            this.v.$model = newValue
          } else {
            this.$emit ('input', newValue)
          }
        }
      },

      labelString () {
        return this.label !== undefined ? this.label : this.$t (labelKey)
      },

      selectableClients () {
        return [...this.additionalItems, ...this.clients, ...this.showInactive ? [{divider: true}, {header: this.$t ('label.inactive')}, ...this.inactiveClients] : []]
      },

      /**
       * define the predicate function, which will be used to filter the
       * client list fetched from BE.
       *
       * @return      predicate function, which argument is the single client,
       *              fetched from BE used for filtering
       */
      preFilter () {
        return this.forAccounting
          // the client selector for accounting should contain
          // members and partners, but not reseller
          ? it => ['Member', 'Partner', 'Agent', 'Internal'].includes (it.type)
          // no filtering by default
          : () => true
      },

      validationMessages () {
        // it is possible to implement the validation message choosing
        // logic here
        return this.vMessages
      }
    },

    created () {
      this.loadClients ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      loadClients () {
        this.$emit ('loading')

        this.fetchData ({
          op: 'client/list/objMgmt',
          params: {
            value: this.forView
          },
          cb: data => {
            this.clients = data.clients
              .filter (this.preFilter)
              .filter ((i) => i.active)
              .map (c => ({
                text: formatClient (c),
                value: c.id
              }))
              .sort ((c1, c2) => {
                return c1.text.localeCompare (c2.text)
              })

            this.inactiveClients = data.clients
              .filter (this.preFilter)
              .filter ((i) => !i.active)
              .map (c => ({
                text: formatClient (c),
                value: c.id,
                inactive: true
              }))
              .sort ((c1, c2) => {
                if (!c1.active && c2.active) return 1
                else if (c1.active && !c2.active) return -1
                return c1.text.localeCompare (c2.text)
              })

            if (this.clients.length === 1) {
              if (this.clients[0].value !== this.value) {
                this.clientId = this.clients[0].value
              }
              this.selectionDisabled = true
            }
          },
          cbFinal: () => {
            this.$emit ('loaded', this.clients)
          }
        })
      },

      focus () {
        this.$refs.input.focus ()
      }
    }
  }
</script>
