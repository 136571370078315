<messages>["../Template"]</messages>

<template>
  <radio-group
    v-model="typeValue"
    :disabled="disabledTypes"
    v-bind="attrs"/>
</template>

<script>
  import RadioGroup from '@/app/core/components/RadioGroup'
  import {USAGE_ERRP} from './TemplateUsageConfig'
  import {
    TEMPLATE_TYPE_DELEGATION,
    TEMPLATE_TYPE_SIMPLE,
    TEMPLATE_TYPE_VALUES
  } from './TemplateTypeConfig'

  export default {
    name: 'TemplateType',

    components: {
      RadioGroup
    },

    props: {
      value: {type: String, required: true},
      usage: {type: String, required: true}
    },

    data () {
      return {
        disabledTypes: []
      }
    },

    computed: {
      typeValue: {
        get () {
          return this.value
        },

        set (newValue) {
          this.$emit ('input', newValue)
        }
      },

      attrs () {
        return {
          defaultValue: TEMPLATE_TYPE_SIMPLE,
          label: this.$t ('operation.title') + ':',
          optionValues: TEMPLATE_TYPE_VALUES,
          column: true,
          optionLabels: TEMPLATE_TYPE_VALUES.map (it => this.$t (`operation.${it}`))
        }
      }
    },

    watch: {
      usage (newValue, oldValue) {
        this.usageChanged (newValue, oldValue)
      }
    },

    created () {
      this.usageChanged (this.usage)
    },

    methods: {
      usageChanged (newValue) {
        switch (newValue) {
          case USAGE_ERRP:
            this.disabledTypes = [TEMPLATE_TYPE_DELEGATION]
            if (this.disabledTypes.includes (this.typeValue)) {
              this.typeValue = TEMPLATE_TYPE_SIMPLE
            }
            break

          default:
            this.disabledTypes = []
            break
        }
      }
    }
  }
</script>
