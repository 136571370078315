<template>
  <v-autocomplete
    ref="input"
    :menu-props="{contentClass: 'registry-selector'}"
    :loading="isLoading"
    :value="value || (multiple ? [] : '')"
    :clearable="clearable"
    :disabled="disabled"
    :multiple="multiple"
    :class="{required}"
    :error-messages="errorMessages"
    :search-input.sync="searchTerm"
    auto-select-first
    persistent-hint
    no-filter
    :label="label"
    :hint="hint"
    :items="searchedList"
    @blur="$emit('blur', $event)"
    @click="searchTerm= ''"
    @input="onInput">
    <template #item="{item, on, attrs}">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-action v-if="multiple">
          <v-simple-checkbox :value="attrs.inputValue" @input="on.click"/>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <span :class="{deletedItemText: item.inactive}" :title="item.text">{{ item.cutText }}</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template
      v-if="multiple"
      #selection="{ item, parent }">
      <v-chip
        :key="item.value"
        close
        outlined
        small
        color="black"
        class="my-1"
        @update:active="parent.selectItem (item)">
        <span
          class="chipContent"
          :title="item.text"
          v-text="item.cutText"/>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
  import {mapActions} from 'vuex'

  import {formatLabel} from '@/app/store/modules/cache'

  export default {
    name: 'RegistrySelect',

    props: {
      value: {
        type: [Array, String, null],
        default: undefined
      },
      multiple: Boolean,
      clearable: Boolean,
      disabled: Boolean,
      required: Boolean,
      errorMessages: {
        type: [Array, String],
        default: undefined
      },
      label: {
        type: String,
        default: undefined
      },
      hint: {
        type: String,
        default: undefined
      },
      /** selectable are only registries with which are ICANN regulated */
      icannRegulated: Boolean,
      /** selectable are only registries with defined launch phases */
      withPhases: Boolean,
      /** selectable also include deleted/inactive registries */
      includeInactive: Boolean
    },

    data () {
      return {
        registryItems: [],
        isLoading: false,
        searchTerm: ''
      }
    },

    computed: {
      searchedList () {
        // sort and return a new array
        const sort = (arr, cb) => {
          return arr.concat ().sort (cb)
        }

        // if search term is given filter
        if (this.searchTerm && this.searchTerm !== '') {
          return sort (
            this.registryItems.filter ((i) => i.text.toLowerCase ().indexOf (this.searchTerm.toLowerCase ()) >= 0),
            (a, b) => {
              // if one contains search term in tlds put them first
              if (a.raw.tlds.includes (this.searchTerm)) {
                return -1
              } else if (b.raw.tlds.includes (this.searchTerm)) {
                return 1
              } else {
                return a.text > b.text
              }
            }
          )
        } else {
          // sort by name in default case
          return sort (this.registryItems, (a, b) => {
            return a.text > b.text
          })
        }
      }
    },

    watch: {
      value: {
        handler (newVal) {
          if (!newVal) {
            this.searchTerm = ''
          } else {
            this.$emit ('input', newVal)
          }
        },
        immediate: true
      }
    },

    created () {
      this.loadRegistryItems ()
    },

    methods: {
      ...mapActions ({
        getRegistryTypeData: 'cache/getRegistryTypeData'
      }),

      /**
       * load available registry data from the backend and populate them as
       * {@code this.registryItems} (alphabetically sorted)
       */
      async loadRegistryItems () {
        this.isLoading = true
        const data = await this.getRegistryTypeData (this.includeInactive)
        const registryItems = []

        for (const prop in data) {
          if (data.hasOwnProperty (prop)) {
            if (
              (!this.withPhases || data[prop].phases) &&
              (!this.icannRegulated || data[prop].icannRegulated)
            ) {
              registryItems.push ({
                text: formatLabel (data[prop]),
                cutText: formatLabel (data[prop], 3),
                value: prop,
                inactive: data[prop].inactive,
                raw: data[prop]
              })
            }
          }
        }

        this.$emit ('registryItemsLoaded', registryItems)
        this.isLoading = false
        this.registryItems = registryItems.sort ((t1, t2) =>
          t1.text.localeCompare (t2.text))
      },

      onInput (newValue) {
        this.$emit ('input', newValue)
        this.searchTerm = ''
      },

      focus () {
        this.$refs.input.focus ()
      }
    }
  }
</script>

<style scoped>
:global(.registry-selector) {
  max-width: 650px
}
</style>
