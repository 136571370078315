import {toASCII as punycodeToASCII} from 'punycode/punycode.es6'

import {helpers} from 'vuelidate/lib/validators'

const asciiDomainExpr = /^([0-9a-zA-Z-_]+\.)+[0-9a-zA-Z-]{2,}$/

const idnLabelExpr =
    /((xn--)[0-9a-zA-Z-_]*[0-9a-zA-Z_])|((?!.{2}--)(?!-)[0-9a-zA-Z-_]*[0-9a-zA-Z_])/

const idnDomainExpr =
    new RegExp ('^(((' + idnLabelExpr.source + ')\\.)+' +
    '(' + idnLabelExpr.source + '))$')

const fqDomainExpr =
    new RegExp ('^(((' + idnLabelExpr.source + ')\\.)+' +
    '((' + idnLabelExpr.source + ')\\.))$')

export const asciiDomain = helpers.regex ('asciiDomain', asciiDomainExpr)
export const idnDomain = helpers.regex ('idnDomain', idnDomainExpr)
export const fqDomain = helpers.regex ('fqDomain', fqDomainExpr)

export const tld = tlds =>
  value => !helpers.req (value) || tlds === null ||
  tlds.some (t => punycodeToASCII (value).endsWith ('.' + punycodeToASCII (t)))
