<messages>["../Template"]</messages>

<template>
  <radio-group
    v-model="typeValue"
    :disabled="disabledTypes"
    v-bind="attrs"/>
</template>

<script>
  import RadioGroup from '@/app/core/components/RadioGroup'
  import {
    OBJECT_TYPE_VALUES,
    OBJECT_TYPE_DOMAIN,
    OBJECT_TYPE_EMAIL,
    OBJECT_TYPE_CONTACT,
    OBJECT_TYPE_DEFAILT_VALUE
  } from './TemplateObjectTypeConfig'
  import {USAGE_WDRP, USAGE_ERRP, USAGE_IRTPC, USAGE_WAP} from './TemplateUsageConfig'

  export default {
    name: 'TemplateObjectType',

    components: {
      RadioGroup
    },

    props: {
      value: {type: String, default: OBJECT_TYPE_DOMAIN},
      usage: {type: String, required: true}
    },

    data () {
      return {
        disabledTypes: []
      }
    },

    computed: {
      typeValue: {
        get () {
          return this.value
        },

        set (newValue) {
          this.$emit ('input', newValue)
        }
      },

      attrs () {
        return {
          defaultValue: OBJECT_TYPE_DEFAILT_VALUE,
          label: this.$t ('objectType.title') + ':',
          optionValues: OBJECT_TYPE_VALUES,
          column: true,
          optionLabels: OBJECT_TYPE_VALUES.map (it => this.$t (`objectType.${it}`))
        }
      }
    },

    watch: {
      usage (newValue, oldValue) {
        this.usageChanged (newValue, oldValue)
      }
    },

    created () {
      this.usageChanged (this.usage)
    },

    methods: {
      usageChanged (newValue) {
        switch (newValue) {
          case USAGE_WDRP:
            this.disabledTypes = [OBJECT_TYPE_CONTACT]

            if (this.disabledTypes.includes (this.typeValue)) {
              this.typeValue = OBJECT_TYPE_DOMAIN
            }
            break

          case USAGE_ERRP:
            this.typeValue = OBJECT_TYPE_DOMAIN
            this.disabledTypes = [OBJECT_TYPE_EMAIL, OBJECT_TYPE_CONTACT]
            break

          case USAGE_IRTPC:
            this.typeValue = OBJECT_TYPE_CONTACT
            this.disabledTypes = [OBJECT_TYPE_DOMAIN, OBJECT_TYPE_EMAIL]
            break

          case USAGE_WAP:
            this.typeValue = OBJECT_TYPE_CONTACT
            this.disabledTypes = [OBJECT_TYPE_DOMAIN, OBJECT_TYPE_EMAIL]
            break
        }
      }
    }
  }
</script>
