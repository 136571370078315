import {helpers} from 'vuelidate/lib/validators'

export const validRegexp = helpers.withParams (
  {type: 'validRegexp'},
  regexpValue => {
    let isValid = true

    if (regexpValue) {
      try {
        RegExp (regexpValue)
      } catch (e) {
        isValid = false
      }
    }

    return isValid
  }
)
