<messages>["./Template"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <div>
    <!-- "template does not exist" alert -->
    <v-alert
      v-if="!isCreate && !isLoading && !isLoaded"
      type="error">
      {{ $t ('error.load', { id: template.id }) }}
    </v-alert>

    <v-card
      v-else
      class="mb-6">
      <form
        novalidate
        @submit.prevent="processTemplateData()">
        <v-card-title primary-title>
          <div>
            <div
              v-t="`template.cu.title.${operationName}`"
              class="text-h5"/>
            <div
              v-t="`${operationName}.intro`"
              class="cgwng-subheading"/>
          </div>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <client-select
                v-if="isClientSelectable"
                v-show="clientCount > 1"
                v-model="template.clientId"
                :required="!hasClient"
                :v="$v.template.clientId"
                @loaded="onClientsLoaded"/>
              <v-text-field
                v-if="isClientVisible && !isClientSelectable"
                :label="$t ('label.client')"
                :value="clientName"
                :disabled="true"/>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="template.name"
                name="ruleName"
                spellcheck="false"
                class="required"
                :label="$t('label.nameLong')"
                :error-messages="validationErrors(
                  'template.name',
                  {
                    required: 'required.name',
                  })"
                @blur="$v.
                  template.name.$touch"/>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="template.priority"
                name="priority"
                class="required"
                type="number"
                :label="$t('label.priority')"
                :error-messages="validationErrors(
                  'template.priority',
                  {
                    required: 'required.priority'
                  })"
                @blur="$v.template.priority.$touch"/>
            </v-col>

            <v-col cols="12">
              <radio-group
                v-model="template.usage"
                v-bind="templateUsage"/>
            </v-col>

            <v-col cols="12">
              <radio-group
                v-model="target"
                v-bind="templateTarget"/>
            </v-col>

            <v-col cols="12">
              <template-object-type
                v-model="template.objectType"
                :usage="template.usage"/>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="template.maintainerPattern"
                name="maintainerPattern"
                spellcheck="false"
                :label="$t('label.maintainerPattern')"
                :error-messages="validationErrors(
                  'template.maintainerPattern',
                  {
                    validRegexp: 'validRegexp.invalidPattern'
                  })"
                @blur="$v.template.maintainerPattern.$touch"/>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="template.languagePattern"
                name="languagePattern"
                spellcheck="false"
                :label="$t('label.languagePattern')"
                :error-messages="validationErrors(
                  'template.languagePattern',
                  {
                    validRegexp: 'validRegexp.invalidPattern'
                  })"
                @blur="$v.template.languagePattern.$touch"/>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="template.countryPattern"
                name="countryPattern"
                spellcheck="false"
                :label="$t('label.countryPattern')"
                :error-messages="validationErrors(
                  'template.countryPattern',
                  {
                    validRegexp: 'validRegexp.invalidPattern'
                  })"
                @blur="$v.template.countryPattern.$touch"/>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <registry-select
                v-model="template.registryTypeNames"
                multiple
                clearable
                icann-regulated
                :label="$t('label.registryTypes')"
                :hint="$t ('label.registryTypesHint')"/>
            </v-col>

            <v-col cols="12">
              <template-type
                v-model="template.templateType"
                :usage="template.usage"/>
            </v-col>

            <v-slide-y-transition>
              <v-col v-if="isTemplateTypeSimple || isTemplateTypeXslt">
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <template v-if="isTemplateTypeSimple">
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model.trim="template.operationTemplate.toAddrs"
                            spellcheck="false"
                            :label="$t('label.toAddrs')"/>
                        </v-col>

                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model.trim="template.operationTemplate.ccAddrs"
                            spellcheck="false"
                            :label="$t('label.ccAddrs')"/>
                        </v-col>

                        <v-col cols="12">
                          <v-text-field
                            v-model.trim="template.operationTemplate.subject"
                            spellcheck="false"
                            :label="$t('label.subject')"/>
                        </v-col>

                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model.trim="template.operationTemplate.bccAddrs"
                            spellcheck="false"
                            :label="$t('label.bccAddrs')"/>
                        </v-col>

                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model.trim="template.operationTemplate.replyToAddrs"
                            spellcheck="false"
                            :label="$t('label.replyToAddrs')"/>
                        </v-col>

                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model.trim="template.operationTemplate.fromAddrs"
                            spellcheck="false"
                            :label="$t('label.fromAddrs')"/>
                        </v-col>

                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model.trim="template.operationTemplate.dispositionNotification"
                            spellcheck="false"
                            :label="$t('label.dispositionNotification')"/>
                        </v-col>

                        <v-col cols="12">
                          <div
                            v-t="'label.plainBody'"
                            class="text-subtitle-1"/>
                          <v-card
                            flat
                            color="grey lighten-4">
                            <macro-template
                              v-model="template.operationTemplate.plainBody"
                              :lines="15"/>
                          </v-card>
                        </v-col>

                        <v-col cols="12">
                          <div
                            v-t="'label.htmlBody'"
                            class="text-subtitle-1"/>
                          <v-card
                            flat
                            color="grey lighten-4">
                            <xml
                              v-model="template.operationTemplate.htmlBody"
                              :lines="15"
                              close-tags/>
                          </v-card>
                        </v-col>
                      </template>

                      <v-col v-else-if="isTemplateTypeXslt" cols="12">
                        <xml
                          v-model="template.operationTemplate.body"
                          :lines="20"
                          close-tags/>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-slide-y-transition>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-alert
            v-model="isErrorVisible"
            dismissible
            type="error">
            <syntax-errors :errors="syntaxErrors"/>
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-t="'general.button.cancel'"
            text
            @click="onCancel"/>

          <v-btn
            v-t="`general.button.${operationName}`" color="primary"
            type="submit"/>
        </v-card-actions>
      </form>
    </v-card>

    <v-card>
      <v-tabs
        v-model="testTab"
        right
        slider-color="primary">
        <!-- tests -->
        <template v-for="mode in testModes">
          <v-tab
            :key="mode"
            v-t="`test.${mode}.tab`"/>
          <v-tab-item :key="mode">
            <template-test
              flat
              :mode="mode"
              :usage="template.usage"
              :type="template.objectType"
              :template="template"/>
          </v-tab-item>
        </template>
      </v-tabs>
    </v-card>
  </div>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'

  import {required, requiredIf} from 'vuelidate/lib/validators'
  import {validRegexp} from '@/app/validators'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import ClientSelect from '@/app/core/components/ClientSelect'
  import RegistrySelect from '@/app/core/components/RegistrySelect'
  import RadioGroup from '@/app/core/components/RadioGroup'
  import TemplateTest from './TemplateTest'
  import TemplateObjectType from './components/TemplateObjectType'
  import TemplateType from './components/TemplateType'
  import SyntaxErrors from './components/SyntaxErrors'
  import MacroTemplate from '@/app/core/components/CodeEditor/MacroTemplate'
  import Xml from '@/app/core/components/CodeEditor/Xml'

  import {
    USAGE_VALUES,
    USAGE_DEFAULT_VALUE
  } from './components/TemplateUsageConfig'
  import {
    TEMPLATE_TYPE_DELEGATION,
    TEMPLATE_TYPE_SIMPLE,
    TEMPLATE_TYPE_XSLT,
    TEMPLATE_TYPE_DEFAULT_VALUE
  } from './components/TemplateTypeConfig'
  import {OBJECT_TYPE_DEFAILT_VALUE} from './components/TemplateObjectTypeConfig'

  import {TEST_MODE_TEMPLATE, TEST_MODE_RULE} from './TemplateTestConfig'

  const DEFAULT_TEMPLATE_PROPERTIES = () => ({
    clientId: undefined,
    usage: USAGE_DEFAULT_VALUE,
    forSelf: true,
    forSubclients: false,
    priority: 1,
    objectType: OBJECT_TYPE_DEFAILT_VALUE,
    maintainerPattern: '',
    languagePattern: '',
    countryPattern: '',
    registryTypeNames: [],
    templateType: TEMPLATE_TYPE_DEFAULT_VALUE,
    operationTemplate: {
      subject: '',
      fromAddrs: '',
      toAddrs: '',
      ccAddrs: '',
      bccAddrs: '',
      replyToAddrs: '',
      dispositionNotification: '',
      body: '',
      plainBody: '',
      htmlBody: ''
    }
  })

  export default {
    name: 'TemplateCreateUpdate',

    components: {
      ClientSelect,
      RadioGroup,
      RegistrySelect,
      TemplateTest,
      TemplateObjectType,
      TemplateType,
      SyntaxErrors,
      MacroTemplate,
      Xml
    },

    mixins: [validationMixins],

    props: {
      templateData: {
        type: Object,
        default: undefined
      },
      isCreate: Boolean
    },

    data () {
      return {
        isLoading: true,
        isLoaded: false,
        isErrorVisible: false,
        syntaxErrors: [],
        testTab: 0,
        availableClients: [],
        template: {
          id: '',
          clientId: 0,
          name: '',
          usage: USAGE_DEFAULT_VALUE,
          forSelf: true,
          forSubclients: false,
          priority: 1,
          objectType: OBJECT_TYPE_DEFAILT_VALUE,
          maintainerPattern: '',
          languagePattern: '',
          countryPattern: '',
          registryTypeNames: [],
          templateType: TEMPLATE_TYPE_DEFAULT_VALUE,
          operationTemplate: {
            subject: '',
            fromAddrs: '',
            toAddrs: '',
            ccAddrs: '',
            bccAddrs: '',
            replyToAddrs: '',
            dispositionNotification: '',
            body: '',
            plainBody: '',
            htmlBody: ''
          }
        }
      }
    },

    validations () {
      return {
        template: {
          clientId: {
            required: requiredIf (() => !this.hasClient)
          },
          name: {required},
          priority: {required},
          maintainerPattern: {validRegexp},
          languagePattern: {validRegexp},
          countryPattern: {validRegexp}
        }
      }
    },

    computed: {
      ...mapState ('auth', [
        'templateLoginData'
      ]),

      ...mapGetters ('auth', [
        'actingClientId',
        'actingClientName',
        'hasClient',
        'hasAnyOfPermissions',
        'mayManageAllEntities',
        'mayManageForeignObjects'
      ]),

      operationName () {
        return this.isCreate ? 'create' : 'update'
      },

      isClientVisible () {
        return this.mayManageForeignObjects
      },

      isClientSelectable () {
        return this.isCreate && this.isClientVisible
      },

      clientCount () {
        return Array.isArray (this.availableClients)
          ? this.availableClients.length
          : 0
      },

      clientName () {
        let clientName = ''

        if (this.template.clientId) {
          clientName = `CORE-${this.template.clientId}`

          if (this.clientCount) {
            const client = this.availableClients.find (c =>
              c.value === this.template.clientId)

            if (client) {
              clientName = client.text
            }
          }
        }

        return clientName
      },

      templateUsage () {
        return this.radioGroupAttrs ('usage', USAGE_DEFAULT_VALUE, USAGE_VALUES)
      },

      target: {
        get () {
          return (this.template.forSelf ? 1 : 0) +
            (this.template.forSubclients ? 2 : 0)
        },

        set (newValue) {
          this.template.forSelf = ((newValue & 1) === 1)
          this.template.forSubclients = ((newValue & 2) === 2)
        }
      },

      templateTarget () {
        return this.radioGroupAttrs ('target', 1, [1, 2, 3])
      },

      isTemplateTypeSimple () {
        return this.template.templateType === TEMPLATE_TYPE_SIMPLE
      },

      isTemplateTypeXslt () {
        return this.template.templateType === TEMPLATE_TYPE_XSLT
      },

      testModes () {
        return [TEST_MODE_RULE, ...(
          this.template.templateType === TEMPLATE_TYPE_DELEGATION
            ? []
            : [TEST_MODE_TEMPLATE]
        )]
      }

    },

    watch: {
      templateData (newData) {
        this.populateTemplateObject (newData)
      },

      'template.templateType' (newValue, oldValue) {
        if (oldValue === TEMPLATE_TYPE_DELEGATION &&
          !this.template.operationTemplate) {
          this.template.operationTemplate =
            Object.assign ({}, DEFAULT_TEMPLATE_PROPERTIES ().operationTemplate)
        }
      }
    },

    created () {
      this.populateTemplateObject (this.templateData)
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess',
        displayWarningMessage: 'notification/setWarning'
      }),
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      /**
       * Stop showing progress bar when clients have been loaded and emit a
       * 'loaded' event
       */
      onClientsLoaded (clients) {
        this.availableClients = clients
      },

      radioGroupAttrs (msgKeyPrefix, defaultValue, optionValues) {
        return {
          defaultValue,
          optionValues,
          label: this.$t (`${msgKeyPrefix}.title`) + ':',
          radioClasses: [],
          column: true,
          optionLabels: optionValues.map (
            it => this.$t (`${msgKeyPrefix}.${it}`))
        }
      },

      // populate this component's template with the values from the dataObject
      populateTemplateObject (dataObject) {
        this.template = Object.assign (
          DEFAULT_TEMPLATE_PROPERTIES (),
          dataObject)

        if (this.template.id) {
          this.loadTemplate (this.template.id)
        }
      },

      loadTemplate (templateId) {
        this.isLoading = true
        this.isLoaded = false

        this.fetchData ({
          op: 'template/load',
          params: {id: templateId},
          cb: data => {
            this.template = data.data
            // this property is used in BE only (for internal name <-> value conversion);
            // if it will not be deleted, then it should be manually kept in sync with
            // `this.template.registryTypeNames`
            delete this.template.registryTypes
            this.isLoaded = true
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      // store or update the template
      processTemplateData () {
        this.isErrorVisible = false
        this.syntaxErrors = []
        if (!this.$v.$invalid) {
          this.fetchData ({
            op: this.isCreate ? 'template/create' : 'template/update',
            params: {
              data: this.template
            },
            cb: () => {
              this.displaySuccessMessage (
                this.$t (`${this.operationName}.success`, {
                  name: this.template.name,
                  id: this.template.id
                }))

              this.$emit ('success')
            },
            cbError: data => {
              const result = data.result
              if (result.length) {
                result.forEach (r => {
                  if (r.code === 'error/syntax') {
                    this.syntaxErrors.push (r.params)
                  }
                })
                if (this.syntaxErrors.length) {
                  this.isErrorVisible = true
                }
              }
            }
          })
        } else {
          this.$v.$touch ()
        }
      },

      onCancel () {
        this.$emit ('cancel')
      }
    }
  }
</script>

<!--
================================================================================
  Styling
================================================================================
-->

<style scoped>
.syntaxErrors td {
  padding-right: 1.5em;
}

.syntaxErrors td:last-child {
  padding-right: 0;
}
</style>
