var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{ref:"input",class:{required: _vm.required},attrs:{"menu-props":{contentClass: 'registry-selector'},"loading":_vm.isLoading,"value":_vm.value || (_vm.multiple ? [] : ''),"clearable":_vm.clearable,"disabled":_vm.disabled,"multiple":_vm.multiple,"error-messages":_vm.errorMessages,"search-input":_vm.searchTerm,"auto-select-first":"","persistent-hint":"","no-filter":"","label":_vm.label,"hint":_vm.hint,"items":_vm.searchedList},on:{"update:searchInput":function($event){_vm.searchTerm=$event},"update:search-input":function($event){_vm.searchTerm=$event},"blur":function($event){return _vm.$emit('blur', $event)},"click":function($event){_vm.searchTerm= ''},"input":_vm.onInput},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[(_vm.multiple)?_c('v-list-item-action',[_c('v-simple-checkbox',{attrs:{"value":attrs.inputValue},on:{"input":on.click}})],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{class:{deletedItemText: item.inactive},attrs:{"title":item.text}},[_vm._v(_vm._s(item.cutText))])])],1)],1)]}},(_vm.multiple)?{key:"selection",fn:function(ref){
var item = ref.item;
var parent = ref.parent;
return [_c('v-chip',{key:item.value,staticClass:"my-1",attrs:{"close":"","outlined":"","small":"","color":"black"},on:{"update:active":function($event){parent.selectItem (item)}}},[_c('span',{staticClass:"chipContent",attrs:{"title":item.text},domProps:{"textContent":_vm._s(item.cutText)}})])]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }