<messages>["./UnsupportedBrowserAlert"]</messages>

<template>
  <v-col v-if="show" cols="12">
    <v-alert
      :value="show"
      prominent
      type="error">
      <h1>{{ title }}</h1>
      {{ text }}
    </v-alert>
  </v-col>
</template>

<script>
  import isUnsupportedBrowser from '@/app/utils/ieDetector'

  export default {
    props: {
      future: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      title () {
        return this.$t (`noBrowserSupport.title.${this.future ? 'future' : 'now'}`)
      },

      text () {
        return this.$t (`noBrowserSupport.text.${this.future ? 'future' : 'now'}`)
      },

      show () {
        return isUnsupportedBrowser () &&
          (
            (this.future && this.ieFutureWarning) || (!this.future && !this.ieFutureWarning)
          )
      },

      ieFutureWarning () {
        const ie = process.env.IE_FUTURE_WARINING
        return ie && ie.toLowerCase () === 'true'
      }
    }
  }
</script>
