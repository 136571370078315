const unsupportedAgents = ['Trident/', 'MSIE ']

const hasUserAgent = (userAgent) => {
  const ua = window.navigator.userAgent
  return ua.indexOf (userAgent) > 0
}

export default () => {
  return unsupportedAgents.some (hasUserAgent)
}
