<messages>["../Template"]</messages>

<template>
  <div
    v-if="errors && errors.length"
    class="syntaxErrorsContainer">
    <h4 v-text="$t ('error.syntax.head')"/>
    <table class="syntaxErrors">
      <thead>
        <tr>
          <th v-text="$t ('error.syntax.location.title')"/>
          <th v-text="$t ('error.syntax.message.title')"/>
          <th/>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="e in errors"
          :key="e">
          <td v-html="getLocationString (e)"/>
          <td
            class="message"
            v-text="e.message"/>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    name: 'SyntaxErrors',

    props: {
      errors: {
        type: Array,
        default: () => []
      }
    },

    methods: {
      getLocationString (e) {
        let location = this.$t ('error.syntax.location.undefined')

        if (e.line > 0 && e.column > 0) {
          location = this.$t ('error.syntax.location.text', e)
        }

        return location
      }
    }
  }
</script>

<style scoped>
.message {
  padding-left: 1em;
}
</style>
