<messages>["./Template"]</messages>

<template>
  <v-card :flat="flat">
    <form
      novalidate
      @submit.prevent="processTemplateTest ()">
      <v-card-title primary-title>
        <div>
          <div
            v-t="`test.${mode}.title`"
            class="text-h5"/>
          <div
            v-t="`test.${mode}.subtitle`"
            class="cgwng-subheading"/>
        </div>
      </v-card-title>

      <v-card-text>
        <template v-if="isTemplatesMode">
          <radio-group
            v-model="testData.usage"
            v-bind="templateUsage"/>

          <template-object-type
            v-model="testData.type"
            :usage="testData.usage"/>
        </template>

        <template v-if="isTemplateMode">
          <radio-group
            v-if="isUsageErrp"
            v-model="testData.errpNotification"
            v-bind="errpNotification"/>
          <radio-group
            v-else-if="isUsageIrtpc"
            v-model="testData.irtpcNotification"
            v-bind="irtpcNotification"/>
          <radio-group
            v-model="testData.type"
            v-bind="templateObjectType"/>
        </template>

        <v-text-field
          v-model.trim="testData.objectName"
          class="required"
          name="objectName"
          :label="$t(`test.objectName.${testData.type}`)"
          :error-messages="validationErrors(
            'testData.objectName',
            {
              required: 'required.objectName'
            })"
          @blur="$v.testData.objectName.$touch"/>

        <registry-select
          v-if="isRegistryRequired"
          v-model="testData.registryType"
          required
          icann-regulated
          :label="$t ('test.registryType')"
          :error-messages="validationErrors(
            'testData.registryType',
            {
              required: 'required.registryType'
            })"
          @blur="$v.testData.registryType.$touch"/>

        <v-text-field
          v-if="isTemplateMode"
          v-model.trim="testData.email"
          class="required"
          name="email"
          :label="$t('test.email')"
          :error-messages="validationErrors(
            'testData.email',
            {
              required: 'required.email',
              email: 'general.invalid.email'
            })"
          @blur="$v.testData.email.$touch"/>
      </v-card-text>

      <v-card-text>
        <v-alert
          v-model="isResultVisible"
          dismissible
          :type="resultType">
          <h4 v-text="$t ('test.result.head')"/>
          <p v-html="resultText"/>

          <syntax-errors :errors="syntaxErrors"/>
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-t="`general.button.submit`" color="primary"
          type="submit"/>
      </v-card-actions>
    </form>
  </v-card>
</template>

<script>
  import {mapActions} from 'vuex'
  import {required, requiredIf} from 'vuelidate/lib/validators'
  import {email} from '@/app/validators'
  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import RegistrySelect from '@/app/core/components/RegistrySelect'
  import RadioGroup from '@/app/core/components/RadioGroup'
  import TemplateObjectType from './components/TemplateObjectType'
  import SyntaxErrors from './components/SyntaxErrors'
  import {
    USAGE_DEFAULT_VALUE,
    USAGE_ERRP,
    USAGE_IRTPC,
    USAGE_VALUES,
    USAGE_WDRP
  } from './components/TemplateUsageConfig'
  import {
    OBJECT_TYPE_DOMAIN, OBJECT_TYPE_CONTACT, OBJECT_TYPE_DEFAILT_VALUE, OBJECT_TYPE_EMAIL, OBJECT_TYPE_VALUES
  } from './components/TemplateObjectTypeConfig'
  import {TEST_MODE_TEMPLATES, TEST_MODE_RULE, TEST_MODE_TEMPLATE} from './TemplateTestConfig'
  import {ERRP_TYPE_DEFAULT_VALUE, ERRP_TYPE_VALUES} from './components/ErrpNotificationTypeConfig'
  import {IRTPC_TYPE_VALUES, IRTPC_TYPE_DEFAULT_VALUE} from './components/IrtpcNotificationTypeConfig'

  export default {
    name: 'TemplateTest',

    components: {
      RadioGroup,
      RegistrySelect,
      TemplateObjectType,
      SyntaxErrors
    },

    mixins: [validationMixins],

    props: {
      mode: {type: String, default: TEST_MODE_TEMPLATES},
      usage: {type: String, default: USAGE_DEFAULT_VALUE},
      type: {type: String, default: OBJECT_TYPE_DEFAILT_VALUE},
      template: {type: Object, default: undefined},
      flat: {type: Boolean, default: false}
    },

    data () {
      return {
        isResultVisible: false,
        resultType: 'error',
        resultText: '',
        syntaxErrors: [],
        testData: {
          usage: this.usage,
          type: this.type,
          objectName: '',
          registryType: '',
          email: '',
          errpNotification: null,
          irtpcNotification: null,
          mode: this.mode
        }
      }
    },

    validations: {
      testData: {
        objectName: {required},
        registryType: {
          required: requiredIf (function () {
            return this.isRegistryRequired
          })
        },
        email: {
          required: requiredIf (function () {
            return this.isTemplateMode
          }),
          email
        }
      }
    },

    computed: {
      templateUsage () {
        return this.radioGroupAttrs ('usage', USAGE_DEFAULT_VALUE, USAGE_VALUES)
      },

      errpNotification () {
        return this.radioGroupAttrs ('errpNotification', ERRP_TYPE_DEFAULT_VALUE, ERRP_TYPE_VALUES)
      },

      irtpcNotification () {
        return this.radioGroupAttrs ('irtpcNotification', IRTPC_TYPE_DEFAULT_VALUE, IRTPC_TYPE_VALUES)
      },

      templateObjectType () {
        const attrs = this.radioGroupAttrs ('objectType', OBJECT_TYPE_DEFAILT_VALUE, OBJECT_TYPE_VALUES)

        if (this.testData.usage === USAGE_WDRP || this.testData.usage === USAGE_ERRP) {
          attrs.disabled = [OBJECT_TYPE_CONTACT]
        }

        return attrs
      },

      isRegistryRequired () {
        return this.testData.type === OBJECT_TYPE_DOMAIN ||
          this.testData.type === OBJECT_TYPE_CONTACT ||
          this.testData.type === OBJECT_TYPE_EMAIL
      },

      isTemplatesMode () {
        return this.mode === TEST_MODE_TEMPLATES
      },

      isTemplateMode () {
        return this.mode === TEST_MODE_TEMPLATE
      },

      isUsageErrp () {
        return this.template.usage === USAGE_ERRP
      },

      isUsageIrtpc () {
        return this.template.usage === USAGE_IRTPC
      }
    },

    watch: {
      usage (newValue) {
        this.testData.usage = newValue
      },

      type (newValue) {
        this.testData.type = newValue
      },

      mode (newValue) {
        this.testData.mode = newValue
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      radioGroupAttrs (msgKeyPrefix, defaultValue, optionValues) {
        return {
          defaultValue,
          optionValues,
          label: this.$t (`${msgKeyPrefix}.title`) + ':',
          column: true,
          optionLabels: optionValues.map (it => this.$t (`${msgKeyPrefix}.${it}`))
        }
      },

      processTemplateTest () {
        this.isResultVisible = false
        this.syntaxErrors = []

        if (!this.$v.$invalid) {
          this.fetchData ({
            op: 'template/test',
            params: {
              data: this.template,
              testData: this.testData
            },
            cb: data => {
              this.resultType = 'success'

              switch (this.mode) {
                case TEST_MODE_TEMPLATES:
                case TEST_MODE_RULE: {
                  let rule = data.template.name

                  if (this.mode === TEST_MODE_TEMPLATES) {
                    const templateHref =
                      this.$router.resolve ({
                        name: 'template.edit',
                        params: {id: data.template.id}
                      }).href
                    rule = `<a href="${templateHref}">${data.template.name}</a>`
                  }

                  this.resultText = this.$t ('test.result.match', {rule})
                  break
                }

                case TEST_MODE_TEMPLATE:
                  this.resultText = this.$t ('test.result.msgsent')
                  break
              }

              this.$emit ('success', this.resultText)
            },
            cbError: data => {
              const msgKeys = []

              const result = data.result

              if (result.length) {
                result.forEach (r => {
                  if (r.code === 'error/syntax') {
                    this.syntaxErrors.push (r.params)
                  } else {
                    switch (r.code) {
                      case 'error/datamgmt/objdoesnotexist':
                        switch (r.params.type) {
                          case 'domain':
                            msgKeys.push ('test.result.nodomain')
                            break
                          case 'contact':
                            msgKeys.push ('test.result.nocontact')
                            break
                          case 'email':
                            msgKeys.push ('test.result.noemail')
                            break
                        }
                        break

                      case 'error/datamgmt/nomatch': {
                        const nomatch = this.mode === TEST_MODE_RULE
                          ? 'nomatchrule'
                          : 'nomatch'

                        msgKeys.push (`test.result.${nomatch}`)
                        break
                      }

                      case 'error/tmpltest/creationerror':
                      case 'error/tmpltest/deliveryfailed':
                      case 'error/tmpltest/domainincompleteregistrants':
                      case 'error/tmpltest/emailincompleteregistrants':
                      case 'error/tmpltest/invalidaddress':
                      case 'error/tmpltest/msgsent':
                      case 'error/tmpltest/nocontact':
                      case 'error/tmpltest/nodomain':
                      case 'error/tmpltest/noemail':
                      case 'error/tmpltest/noprevcontact':
                      case 'error/tmpltest/noprevdomain':
                      case 'error/tmpltest/noprevemail': {
                        const suffix = r.code.substr ('error/tmpltest/'.length)
                        msgKeys.push (`test.result.${suffix}`)
                        break
                      }
                    }
                  }
                })
              }

              this.resultType = 'error'

              if (!msgKeys.length) {
                msgKeys.push ('error.unknown')
              }

              this.resultText = msgKeys.map (key => `${this.$t (key)}<br/>`).join ()

              this.$emit ('error', this.resultText)
            },
            cbFinal: () => {
              this.isResultVisible = true
            }
          })
        } else {
          this.$v.$touch ()
        }
      }
    }
  }
</script>
