<template>
  <v-container
    fluid
    class="pa-0">
    <v-row :class="maxWidth">
      <unsupported-browser-alert/>
      <slot/>
    </v-row>
  </v-container>
</template>

<script>
  import UnsupportedBrowserAlert from '@/app/core/components/UnsupportedBrowserAlert'

  export default {
    name: 'BaseLayout',

    components: {
      UnsupportedBrowserAlert
    },

    props: {
      mw: {
        type: String,
        default: undefined
      },
      mw0: {
        type: Boolean,
        default: false
      },
      mw1: {
        type: Boolean,
        default: false
      },
      mw2: {
        type: Boolean,
        default: false
      },
      mw3: {
        type: Boolean,
        default: false
      },
      mw4: {
        type: Boolean,
        default: true
      }
    },

    computed: {
      maxWidth () {
        if (this.mw) return 'mw-' + this.mw
        else if (this.mw0) return 'mw-0'
        else if (this.mw1) return 'mw-1'
        else if (this.mw2) return 'mw-2'
        else if (this.mw3) return 'mw-3'
        else if (this.mw4) return 'mw-4'

        return undefined
      }
    }
  }
</script>
