var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":_vm.flat}},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();_vm.processTemplateTest ()}}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',[_c('div',{directives:[{name:"t",rawName:"v-t",value:(("test." + _vm.mode + ".title")),expression:"`test.${mode}.title`"}],staticClass:"text-h5"}),_c('div',{directives:[{name:"t",rawName:"v-t",value:(("test." + _vm.mode + ".subtitle")),expression:"`test.${mode}.subtitle`"}],staticClass:"cgwng-subheading"})])]),_c('v-card-text',[(_vm.isTemplatesMode)?[_c('radio-group',_vm._b({model:{value:(_vm.testData.usage),callback:function ($$v) {_vm.$set(_vm.testData, "usage", $$v)},expression:"testData.usage"}},'radio-group',_vm.templateUsage,false)),_c('template-object-type',{attrs:{"usage":_vm.testData.usage},model:{value:(_vm.testData.type),callback:function ($$v) {_vm.$set(_vm.testData, "type", $$v)},expression:"testData.type"}})]:_vm._e(),(_vm.isTemplateMode)?[(_vm.isUsageErrp)?_c('radio-group',_vm._b({model:{value:(_vm.testData.errpNotification),callback:function ($$v) {_vm.$set(_vm.testData, "errpNotification", $$v)},expression:"testData.errpNotification"}},'radio-group',_vm.errpNotification,false)):(_vm.isUsageIrtpc)?_c('radio-group',_vm._b({model:{value:(_vm.testData.irtpcNotification),callback:function ($$v) {_vm.$set(_vm.testData, "irtpcNotification", $$v)},expression:"testData.irtpcNotification"}},'radio-group',_vm.irtpcNotification,false)):_vm._e(),_c('radio-group',_vm._b({model:{value:(_vm.testData.type),callback:function ($$v) {_vm.$set(_vm.testData, "type", $$v)},expression:"testData.type"}},'radio-group',_vm.templateObjectType,false))]:_vm._e(),_c('v-text-field',{staticClass:"required",attrs:{"name":"objectName","label":_vm.$t(("test.objectName." + (_vm.testData.type))),"error-messages":_vm.validationErrors(
          'testData.objectName',
          {
            required: 'required.objectName'
          })},on:{"blur":_vm.$v.testData.objectName.$touch},model:{value:(_vm.testData.objectName),callback:function ($$v) {_vm.$set(_vm.testData, "objectName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"testData.objectName"}}),(_vm.isRegistryRequired)?_c('registry-select',{attrs:{"required":"","icann-regulated":"","label":_vm.$t ('test.registryType'),"error-messages":_vm.validationErrors(
          'testData.registryType',
          {
            required: 'required.registryType'
          })},on:{"blur":_vm.$v.testData.registryType.$touch},model:{value:(_vm.testData.registryType),callback:function ($$v) {_vm.$set(_vm.testData, "registryType", $$v)},expression:"testData.registryType"}}):_vm._e(),(_vm.isTemplateMode)?_c('v-text-field',{staticClass:"required",attrs:{"name":"email","label":_vm.$t('test.email'),"error-messages":_vm.validationErrors(
          'testData.email',
          {
            required: 'required.email',
            email: 'general.invalid.email'
          })},on:{"blur":_vm.$v.testData.email.$touch},model:{value:(_vm.testData.email),callback:function ($$v) {_vm.$set(_vm.testData, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"testData.email"}}):_vm._e()],2),_c('v-card-text',[_c('v-alert',{attrs:{"dismissible":"","type":_vm.resultType},model:{value:(_vm.isResultVisible),callback:function ($$v) {_vm.isResultVisible=$$v},expression:"isResultVisible"}},[_c('h4',{domProps:{"textContent":_vm._s(_vm.$t ('test.result.head'))}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.resultText)}}),_c('syntax-errors',{attrs:{"errors":_vm.syntaxErrors}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:("general.button.submit"),expression:"`general.button.submit`"}],attrs:{"color":"primary","type":"submit"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }